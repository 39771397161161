import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import { useLang } from "../../helpers/language";
import NProgress, { set } from "nprogress";
import { useSocket } from "../../helpers/socket";
import {
	ActionIcon,
	Avatar,
	Box,
	Button,
	Card,
	CloseIcon,
	Divider,
	Flex,
	Group,
	Image,
	Indicator,
	rem,
	ScrollArea,
	Table,
	Text,
	useMantineColorScheme
} from "@mantine/core";
import {
	IconPaperclip,
	IconPin,
	IconSearch,
	IconStar,
	IconTrash,
	IconUser
} from "@tabler/icons-react";
import Message from "./message/Message";
import Forward from "./forward/forward";
import UserInfo from "./user-info/UserInfo";
import Search from "./search/search";
import FileSearch from "./fileSearch/fileSearch";
import PinnedStared from "./pinned-stared/PinnedStared";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Reply from "./reply/reply";
import Input from "../modules/input";

export default function ChatDirectAdd() {
	const [isBusy, setBusy] = useState(true);
	let id = useLocation().pathname.split("/")[3];
	const { connectedUsers = [], socket, setNewNotification } = useSocket();
	const { lang } = useLang();
	const { user, setUser } = useUser();
	const [permissions, setPermissions] = useState([]);
	const { colorScheme } = useMantineColorScheme();
	const viewport = useRef(null);
	const [partner, setPartner] = useState([]);
	const [messages, setMessages] = useState([]);
	const [favorite, setFavorite] = useState(false);
	const [input, setInput] = useState("");
	const [forwardData, setForwardData] = useState({});
	const [replyData, setReplyData] = useState({});
	const [pinnedMessages, setPinnedMessages] = useState(null);
	const [starredMessages, setStarredMessages] = useState(null);
	const [uploadFiles, setUploadFiles] = useState([]);
	const uploadFilesRef = useRef(uploadFiles);

	const [audioFile, setAudioFile] = useState(null);
	const audioFileRef = useRef(audioFile);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [uploadSuccess, setUploadSuccess] = useState(false);

	useEffect(() => {
		uploadFilesRef.current = uploadFiles;
	}, [uploadFiles]);

	useEffect(() => {
		audioFileRef.current = audioFile;
	}, [audioFile]);

	useEffect(() => {}, [messages]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
		// eslint-disable-next-line
	}, []);

	const HandlePermissionCheck = (input) => {
		if (input) {
			if (user?.ananunaki) {
				input.crud = {
					create: true,
					read: true,
					update: true,
					delete: true
				};
				return input;
			}
			let foundedPermission = user.role?.permissions?.find(
				(permission) => permission.name === "chat"
			);
			if (foundedPermission) {
				let foundedInput = foundedPermission.inputs?.find(
					(inp) => inp.name === input?.permissionModel
				);
				if (foundedInput && foundedInput.read) {
					input.crud = {
						create: foundedInput.create !== undefined ? foundedInput.create : false,
						read: foundedInput.read !== undefined ? foundedInput.read : false,
						update: foundedInput.update !== undefined ? foundedInput.update : false,
						delete: foundedInput.delete !== undefined ? foundedInput.delete : false
					};
					return input;
				}
			} else {
				console.error("Permission not found 2");
			}
		}
	};

	// let checkedInput = HandlePermissionCheck({
	// 	value: input,
	// 	setter: setInput,
	// 	type: "textarea",
	// 	usageType: "textarea",
	// 	label: "",
	// 	permissionModel: "textarea"
	// });

	// const editor = useEditor({
	// 	extensions: [
	// 		StarterKit,
	// 		Placeholder.configure({ placeholder: "Type message" }),
	// 		Underline,
	// 		TextAlign,
	// 		Superscript,
	// 		SubScript
	// 	],
	// 	content: input,
	// 	onUpdate: ({ editor }) => {
	// 		if (editor.getHTML() === "<p></p>") {
	// 			setInput("");
	// 		} else {
	// 			setInput(editor.getHTML());
	// 		}
	// 	}
	// });

	const [componentVisibility, setComponentVisibility] = useState({
		forwardOpened: false,
		userInfoDrawer: false,
		search: false,
		fileSearch: false,
		pinned: false,
		stared: false,
		reply: false
	});

	const toggleComponentVisibility = (component) => {
		setComponentVisibility((prevState) => ({
			...prevState,
			[component]: !prevState[component]
		}));
	};

	const onForwardOpen = (msg) => {
		setForwardData({
			forwardMessage: msg.body,
			forwardUser: msg.from === user._id ? user : partner
		});
		toggleComponentVisibility("forwardOpened");
	};

	const scrollToBottom = () =>
		viewport?.current?.scrollTo({
			top: viewport?.current.scrollHeight,
			behavior: "smooth"
		});

	const sendMessage = (socket) => {
		if (input) {
			let newMessage = {
				from: user._id,
				to: [id],
				body: input,
				readBy: [user._id],
				createdAt: new Date()
			};
			setInput("");
			socket.emit("sendMessage", {
				newMessage
			});
		}
	};

	const onMessageDelete = async ({ msg, deleteForEveryone }) => {
		socket.emit("deleteMessage", {
			message: msg,
			deleteForEveryone,
			userId: user._id,
			partnerId: partner._id
		});
		setBusy(true);
	};

	const setFavoriteToApi = async () => {
		if (!favorite) {
			if (
				user.favorites?.find((favorite) => favorite._id === partner._id) ||
				user.favorites?.find((favorite) => favorite === partner._id)
			) {
				setFavorite(true);
				return;
			}
			let updatedUser = await API.update(config.api.user, user._id, {
				favorites: [...user.favorites, id]
			});
			if (updatedUser) {
				setUser({ ...user, favorites: [...updatedUser.favorites] });
				setFavorite(true);
			}
		} else {
			let updatedUser = await API.update(config.api.user, user._id, {
				favorites: user.favorites.filter((favorite) => favorite._id !== partner._id)
			});
			if (updatedUser) {
				setUser({ ...user, favorites: [...updatedUser.favorites] });
			}
			setFavorite(false);
		}
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			let partner = await API.get({
				route: config.api.user,
				query: {
					_id: id
				},
				uploadType: true,
				limit: 1,
				populate: ["department", "position", "role"]
			});
			if (partner) setPartner(partner[0]);

			user.favorites?.forEach((favorite) => {
				if (favorite?._id === partner?.[0]?._id) {
					setFavorite(true);
				}
			});

			try {
				let rawMessages = await API.get({
					route: config.api.chat,
					query: {
						$or: [
							{ $and: [{ from: id }, { to: user._id }] },
							{ $and: [{ to: id }, { from: user._id }] }
						],
						deletedFor: {
							$nin: [user._id]
						}
					},
					sort: {
						createdAt: -1
					},
					populate: ["forward.from", "from"],
					// limit: 10
					uploadType: true
				});

				if (rawMessages) setMessages(rawMessages);
			} catch (error) {
				console.log("🚀 ~ error:", error);
			}
			setTimeout(() => {
				scrollToBottom();
			}, 0);
			NProgress.done();
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		socket?.on("messageUpdated", async (message) => {
			console.log("🚀 ~ socket?.on ~ message:", message);
			let messageUploads = await API.get({
				route: config.api.upload,
				query: {
					obj_id: message._id
				}
			});
			if (messageUploads) {
				message.uploads = messageUploads;
			}
			setMessages((oldMessages) => {
				const updatedMessages = oldMessages.map((msg) => (msg._id === message._id ? message : msg));
				return [...updatedMessages];
			});
		});

		return () => {
			socket?.off("messageUpdated", (data) => {});
		};
	}, [socket]);

	useEffect(() => {
		socket?.on("newMessage", async (newMessage) => {
			let localNewMessage = { ...newMessage };
			if (uploadFilesRef?.current?.length > 0) {
				try {
					let uploadPromises = uploadFilesRef?.current?.map((file) =>
						API.upload(localNewMessage._id, [file], lang, "files", () => {}, new Date())
					);
					let results = await Promise.all(uploadPromises);
					let uploads = results.flat();
					if (uploads && Array.isArray(uploads)) {
						localNewMessage.uploads = uploads;
						setUploadFiles([]);
						socket.emit("messageUploadDone", {
							message: localNewMessage
						});
					}
				} catch (error) {
					console.log("🚀 ~ socket?.on ~ error:", error);
				}
			}
			if (audioFileRef.current) {
				try {
					let uploadPromises = [
						API.upload(localNewMessage._id, [audioFileRef.current], lang, "audio", () => {})
					];
					let results = await Promise.all(uploadPromises);
					let uploads = results.flat();
					if (uploads && Array.isArray(uploads)) {
						localNewMessage.uploads = uploads;
						setAudioFile(null);
						socket.emit("messageUploadDone", {
							message: localNewMessage
						});
					}
				} catch (error) {
					console.log("🚀 ~ socket?.on ~ error:", error);
				}
			}
			if (localNewMessage.from === user?._id) {
				console.log("🚀 ~ THIS IS SENDER");
				setMessages((oldMessages) => {
					return [...oldMessages, localNewMessage];
				});
			} else {
				console.log("🚀 ~ THIS IS RECIEVER");
				try {
					let partner = await API.get({
						route: config.api.user,
						query: {
							_id: localNewMessage.from
						},
						uploadType: true,
						limit: 1,
						populate: ["department", "position", "role"]
					});
					let messageBody = localNewMessage?.body
						? localNewMessage?.body.length > 30
							? localNewMessage?.body.slice(0, 30)
							: localNewMessage?.body
						: "";
					setNewNotification({
						ID: new Date().getTime(),
						date: new Date().toISOString(),
						name: partner[0].name || {
							en: "no data provided",
							ru: "no data provided",
							hy: "no data provided"
						},
						desc: {
							en: messageBody,
							ru: messageBody,
							hy: messageBody
						},
						importance: "medium",
						route: `${config.api.chat}/${partner?._id}`,
						createdByUser: partner[0],
						targetUsers: [
							{
								user: user?._id,
								read: false
							}
						]
					});
					setMessages((oldMessages) => {
						return [...oldMessages, localNewMessage];
					});
				} catch (error) {
					console.log("🚀 ~ error:", error);
				}
			}

			setTimeout(() => {
				scrollToBottom();
			}, 0);
		});

		socket?.on("messageDeleted", async (message) => {
			setMessages((oldMessages) => {
				return oldMessages.filter((msg) => msg._id !== message._id);
			});
		});

		return () => {
			socket?.off("newMessage", (data) => {});
			socket?.off("messageDeleted", (data) => {});
		};
		// eslint-disable-next-line
	}, [socket]);

	let messagePreviousDate = null;
	if (isBusy) return <Loading />;
	else
		return (
			<>
				<Box padding="md">
					<Card withBorder radius="sm">
						<Flex justify={"space-between"} align={"center"}>
							<Flex align={"center"}>
								<Indicator
									inline
									disabled={connectedUsers && !connectedUsers?.map((user) => user._id).includes(id)}
									processing
									color="green"
									size={14}
								>
									<Avatar
										size="lg"
										radius="sm"
										src={`${config.api.API_URL}/${partner?.uploads?.[0].path}` || ""}
									/>
								</Indicator>
								<Text size="xl" ml={20}>
									{partner?.name?.[lang] || "no data provided"}
								</Text>
								<Text size="sm" ml={20}>
									{partner?.role?.[0].name?.[lang] || "no data provided"}
								</Text>
								<Button w={40} variant="subtle" p={5} size="xs" onClick={setFavoriteToApi}>
									{favorite ? (
										<Image
											src="/assets/favorite-interface-multimedia-svgrepo-com.svg"
											alt="star"
											style={{
												filter: `invert(${favorite ? "0" : "1"})`
											}}
											width={20}
											height={20}
										/>
									) : (
										<Image
											src="/assets/favorite-svgrepo-com.svg"
											alt="star"
											style={{
												filter: `invert(${favorite ? "0" : "1"})`
											}}
											width={20}
											height={20}
										/>
									)}
								</Button>
							</Flex>
							<Group justify="right" gap="xs">
								<ActionIcon
									variant="default"
									aria-label="Settings"
									onClick={() => toggleComponentVisibility("userInfoDrawer")}
								>
									<IconUser style={{ width: rem(16), height: rem(16) }} stroke={2} />
								</ActionIcon>
								<ActionIcon
									variant="default"
									aria-label="Settings"
									onClick={() => toggleComponentVisibility("search")}
								>
									<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={2} />
								</ActionIcon>
								<ActionIcon
									variant="default"
									aria-label="Settings"
									onClick={() => toggleComponentVisibility("fileSearch")}
								>
									<IconPaperclip style={{ width: rem(16), height: rem(16) }} stroke={2} />
								</ActionIcon>
								<ActionIcon
									variant="default"
									aria-label="Pinned"
									onClick={async () => {
										try {
											const pinnedMessages = await API.get({
												route: config.api.chat,
												query: {
													$or: [
														{ $and: [{ from: id }, { to: user._id }] },
														{ $and: [{ to: id }, { from: user._id }] }
													],
													deletedFor: {
														$nin: [user._id]
													},
													pinned: true
												},
												sort: {
													createdAt: -1
												}
											});
											if (pinnedMessages) {
												setPinnedMessages(pinnedMessages);
											}
										} catch (e) {
											console.log(e.message);
										}
										toggleComponentVisibility("pinned");
									}}
								>
									<IconPin style={{ width: rem(16), height: rem(16) }} stroke={2} />
								</ActionIcon>
								<ActionIcon
									variant="default"
									aria-label="Starred"
									onClick={async () => {
										try {
											const starredMessages = await API.get({
												route: config.api.chat,
												query: {
													$or: [
														{ $and: [{ from: id }, { to: user._id }] },
														{ $and: [{ to: id }, { from: user._id }] }
													],
													deletedFor: {
														$nin: [user._id]
													},
													starred: true
												},
												sort: {
													createdAt: -1
												}
											});
											if (starredMessages) {
												setStarredMessages(starredMessages);
											}
										} catch (e) {
											console.log(e.message);
										}
										toggleComponentVisibility("stared");
									}}
								>
									<IconStar style={{ width: rem(16), height: rem(16) }} stroke={2} />
								</ActionIcon>
							</Group>
						</Flex>
					</Card>
				</Box>
				<ScrollArea viewportRef={viewport} style={{ height: "calc(100vh - 90px)" }}>
					<Table
						highlightOnHover
						withRowBorders={false}
						spacing="xs"
						style={{
							borderRadius: 3
						}}
					>
						<Table.Tbody>
							{messages?.map((msg, index) => {
								const currentDate = moment(msg.createdAt).format("DD-MM-YYYY");
								const showDateHeader = currentDate !== messagePreviousDate;
								messagePreviousDate = currentDate;
								return (
									<Table.Tr>
										<div key={msg._id}>
											{showDateHeader && (
												<Divider
													my="xs"
													label={moment(msg.createdAt).format("DD.MM.YYYY")}
													labelPosition="center"
												/>
											)}
											<Message
												message={msg}
												fromUser={msg.from}
												toUser={partner}
												user={user}
												setBusy={setBusy}
												isBusy={isBusy}
												setFiles={setUploadFiles}
												onRead={(userId) => {
													socket.emit("readMessage", {
														message: msg,
														userId
													});
												}}
												onReply={() => {
													toggleComponentVisibility("reply");
													setReplyData(msg);
												}}
												onForward={() => {
													onForwardOpen(msg);
												}}
												onDelete={async (deleteForEveryone) => {
													try {
														await onMessageDelete({ msg, deleteForEveryone });
													} catch (e) {
														console.log(e.message);
													}
												}}
												onPinned={async () => {
													setBusy(true);
													try {
														await API.update("message", msg._id, { pinned: !msg.pinned });
													} catch (e) {
														console.log(e.message);
													} finally {
														setBusy(false);
													}
												}}
												onStarred={async () => {
													setBusy(true);
													try {
														await API.update("message", msg._id, { starred: !msg.starred });
													} catch (e) {
														console.log(e.message);
													} finally {
														setBusy(false);
													}
												}}
											/>
										</div>
									</Table.Tr>
								);
							})}
						</Table.Tbody>
					</Table>
				</ScrollArea>
				<UserInfo
					open={componentVisibility.userInfoDrawer}
					onClose={() => toggleComponentVisibility("userInfoDrawer")}
					data={partner}
				/>
				<Search
					open={componentVisibility.search}
					onClose={() => toggleComponentVisibility("search")}
				/>
				<FileSearch
					open={componentVisibility.fileSearch}
					onClose={() => toggleComponentVisibility("fileSearch")}
				/>
				<Forward
					opened={componentVisibility.forwardOpened}
					onClose={() => toggleComponentVisibility("forwardOpened")}
					data={forwardData}
				/>
				<PinnedStared
					open={componentVisibility.pinned}
					onClose={() => toggleComponentVisibility("pinned")}
					title="Pinned messages"
					data={pinnedMessages}
					partner={partner}
				/>
				<PinnedStared
					open={componentVisibility.stared}
					onClose={() => toggleComponentVisibility("stared")}
					title="Stared messages"
					data={starredMessages}
					partner={partner}
				/>
				<Reply
					open={componentVisibility.reply}
					onClose={() => toggleComponentVisibility("reply")}
					title="Reply"
					data={replyData}
				/>
				<Group position="apart" style={{ marginTop: "auto", padding: "10px 0" }} grow={1}>
					<Flex align="center" direction={"column"} w={"100%"}>
						{uploadFiles?.length > 0 && (
							<ScrollArea w={"100%"} mb={10} scrollbars="x" viewportRef={viewport}>
								<Flex align="end" direction={"row"} justify="center" wrap>
									{uploadFiles?.map((file, index) => (
										<Card key={index} shadow="sm" padding="lg" m={10} radius="md" withBorder>
											{file.type.startsWith("image/") && (
												<Image
													src={URL.createObjectURL(file)}
													alt={file.name}
													width={100}
													height={100}
												/>
											)}

											<Text size="sm" mt={14}>
												{file.name.slice(0, 10)}
											</Text>

											<Button
												onClick={() => {
													setUploadFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
												}}
												color="red"
												style={{
													position: "absolute",
													top: 5,
													right: 5,
													opacity: 0.8,
													width: "20px",
													height: "20px",
													padding: "0"
												}}
											>
												<CloseIcon
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
													m={0}
												/>
											</Button>
										</Card>
									))}
								</Flex>
							</ScrollArea>
						)}

						<Input
							value={input}
							setter={setInput}
							type="textarea"
							usageType="textarea"
							attachment={{
								files: uploadFiles,
								setter: setUploadFiles
							}}
							audioRecorder={{
								setter: async () => {
									console.log("🚀 ~ audioFile:", audioFile);
									if (!audioFile) return;
									// try {
									// 	const response = await API.upload(
									// 		"1234134134134", // Replace with your object ID
									// 		[audioFile],
									// 		"en", // Language
									// 		"audio-destination", // Destination
									// 		0, // Thumbnail width (not relevant for audio)
									// 		0, // Thumbnail height (not relevant for audio)
									// 		false, // Responsive images (not relevant for audio)
									// 		"Recorded audio", // Alt text
									// 		setUploadProgress // Progress callback
									// 	);
									// 	console.log("Upload successful:", response);
									// 	setUploadSuccess(true);
									// } catch (error) {
									// 	console.error("Upload failed:", error);
									// }
								},
								audioURL: "",
								setAudioURL: () => {},
								uploadProgress: uploadProgress,
								uploadSuccess: uploadSuccess,
								audioFile: audioFile,
								setAudioFile: setAudioFile
							}}
							lang={lang}
							crud={{
								read: true,
								update: true
							}}
							textAreaProps={{
								submit: () => sendMessage(socket),
								submitOnEnter: true,
								attachment: true,
								audioRecorder: true,
								emoji: true
							}}
						/>
					</Flex>
				</Group>
			</>
		);
}
