import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useSocket } from "../../helpers/socket";
import config from "../../config";
import { useLang } from "../../helpers/language";
import { useDisclosure } from "@mantine/hooks";
import parse from "html-react-parser";
import { useMobile } from "../../helpers/is-mobile";
import {
	Avatar,
	Text,
	Group,
	Card,
	Grid,
	Modal,
	Button,
	List,
	ScrollArea,
	useMantineColorScheme,
	Flex,
	Notification
} from "@mantine/core";
import { IconNotification } from "@tabler/icons-react";
import { T } from "../../helpers/translator";
import { useHistory } from "react-router-dom";

export default function Notifications() {
	const { myNotifications } = useSocket();
	const audio = useRef(null);
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [opened, OpenClose] = useDisclosure(false);
	const isMobile = useMobile();
	const history = useHistory();

	useEffect(() => {
		audio?.current?.play();
	}, [myNotifications]);

	return (
		<>
			<Button
				justify="center"
				width
				onClick={OpenClose.toggle}
				p={5}
				size="xs"
				variant="default"
				mt={5}
			>
				<IconNotification
					size="1rem"
					style={{ marginRight: isMobile ? "0" : "10px" }}
					color={colorScheme === "dark" ? "white" : "black"}
				/>
				{isMobile ? "" : T("notifications", lang)}
			</Button>
			<Modal
				opened={opened}
				onClose={OpenClose.close}
				title={T("notifications", lang)}
				centered
				size="auto"
			>
				<Card withBorder shadow="sm" radius="md">
					<Card.Section withBorder inheritPadding py="xs">
						{myNotifications?.length > 0 ? (
							<ScrollArea style={{ maxHeight: "50vh" }} className="mantine-scroll-area">
								<List shadow="xs" padding="xs" styles={{ list: { padding: 0 } }}>
									{myNotifications?.map((notification, index) => {
										return (
											<List.Item
												style={{ cursor: "pointer", userSelect: "none", listStyleType: "none" }}
												onClick={() => {
													console.log(notification);
													OpenClose.close();
													history.go(notification?.route ? "/" + notification?.route : "/");
												}}
												key={index}
												hover
												listStyleType="none"
											>
												<Notification
													icon={
														<Avatar
															variant="filled"
															radius="md"
															size="xl"
															src={
																`${config.api.API_URL}/${notification.createdByUser?.uploads?.[0]?.path}` ||
																""
															}
														/>
													}
													title={notification.name?.[lang]}
													color={
														notification.importance === "high"
															? "red"
															: notification.importance === "medium"
																? "orange"
																: "blue"
													}
													withCloseButton={false}
												>
													{parse(notification?.desc?.[lang] ? notification?.desc?.[lang] : "")}
												</Notification>
												<Text
													style={{
														position: "absolute",
														right: 0,
														top: 0,
														padding: "5px",
														color: "gray",
														fontSize: "0.8rem"
													}}
												>
													{moment(notification.createdAt).fromNow()}
												</Text>
											</List.Item>
										);
									})}
								</List>
							</ScrollArea>
						) : (
							<Text align="center" size="xl">
								{T("noNotifications", lang)}
							</Text>
						)}
					</Card.Section>
				</Card>
			</Modal>
		</>
	);
}
