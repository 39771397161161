import * as API from "./api";
import Swal from "sweetalert2";
import config from "../config";
import { notifications } from "@mantine/notifications";
import NProgress from "nprogress";
import { T } from "./translator";

//   const location = useLocation();

//   let history = useHistory();
//   let editMode;
//   let editedPageTitle = "";
//   const { lang, setLang } = useLang();
//   const { user } = useUser();

//   const interactivePageTitle = () => {
//     let result = "";
//     config.menu.forEach((item) => {
//       if (item.route) {
//         if (location?.pathname.indexOf(item.route) > 0) {
//           result = item.name[lang];
//         }
//       } else {
//         item.subMenu.forEach((innerItem) => {
//           if (location?.pathname.indexOf(innerItem.route) > 0) {
//             result = innerItem.name[lang];
//           }
//         });
//       }
//     });
//     return result;
//   };

//   if (useParams().id === "add") {
//     editMode = false;
//     editedPageTitle = `${
//       T('addNew')
//     } ${interactivePageTitle()}`;
//   } else {
//     editMode = true;
//     editedPageTitle = `${
//       T('edit')
//     } ${interactivePageTitle()}`;
//   }

export const CREATE = async ({
	modelSendToServer,
	route,
	history,
	setBusy,
	lang,
	user,
	filesComponent
}) => {
	return new Promise((resolve, reject) => {
		Swal.fire({
			title: T("areYouSure", lang),
			text: T("youCannotRevertThisAction", lang),
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: T("yes", lang)
		}).then(async (result) => {
			if (result.isConfirmed) {
				NProgress.start();
				if (setBusy) setBusy(true);
				modelSendToServer.temporary = false;
				try {
					modelSendToServer.history = [
						{
							date: new Date(),
							user: user ? user._id : "",
							desc: T("created", lang)
						}
					];
					let result = await API.post(route, modelSendToServer);

					if (filesComponent) {
						for (const component of filesComponent) {
							if (
								(component.file && component.file.length > 0) ||
								(component.files && component.files.length > 0)
							) {
								try {
									let uploadResult = await API.upload(
										result._id,
										component.files || component.file,
										lang,
										component.destiny,
										component.thumbSizeWidth,
										component.thumbSizeHeight,
										component.responsiveImages,
										component.alt,
										() => {},
										new Date()
									);
									if (uploadResult && result) {
										if (history) history.goBack("");
										notifications.show(config.notificationSettings.save("success", lang));
										if (setBusy) setBusy(true);
										resolve(result);
										return;
									} else {
										notifications.show(config.notificationSettings.save("error", lang));
									}
								} catch (error) {
									console.error(error);
								}
							} else {
								if (result) {
									if (history) history.goBack("");
									notifications.show(config.notificationSettings.save("success", lang));
									if (setBusy) setBusy(true);
									resolve(result);
									return;
								} else {
									notifications.show(config.notificationSettings.save("error", lang));
								}
							}
						}
					} else {
						if (result) {
							if (history) history.goBack("");
							notifications.show(config.notificationSettings.save("success", lang));
							if (setBusy) setBusy(true);
							resolve(result);
							return;
						} else {
							notifications.show(config.notificationSettings.save("error", lang));
						}
					}
					resolve(result);
				} catch (error) {
					console.error(error);
					reject(error);
				}
			} else {
				resolve(false);
			}
		});
	});
};
export const UPDATE = async ({
	modelSendToServer,
	route,
	history,
	setBusy,
	id,
	connectedRoute,
	connectedData,
	connectedID,
	lang,
	user,
	filesComponent,
	hardReload,
	Status
}) => {
	if (modelSendToServer) {
		return Swal.fire({
			title: T("areYouSure", lang),
			text: T("youCannotRevertThisAction", lang),
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: T("yes", lang)
		}).then(async (result) => {
			if (result.isConfirmed) {
				NProgress.start();
				modelSendToServer.temporary = false;
				modelSendToServer.history =
					modelSendToServer?.history && modelSendToServer?.history.length > 0
						? [
								...modelSendToServer?.history,
								{
									date: new Date(),
									user: user ? user._id : null,
									desc: Status ? Status?.name : ""
								}
							]
						: [
								{
									date: new Date(),
									user: user ? user._id : null,
									desc: Status ? Status?.name : ""
								}
							];
				let result;
				try {
					result = await API.update(route, id, modelSendToServer);
					// handle upload files\
					if (filesComponent) {
						filesComponent.map(async (component) => {
							if (
								(component.file && component.file.length > 0) ||
								(component.files && component.files.length > 0)
							) {
								try {
									let uploadResult = await API.upload(
										result._id,
										component.files || component.file,
										lang,
										component.destiny,
										component.thumbSizeWidth,
										component.thumbSizeHeight,
										component.responsiveImages,
										component.alt,
										() => {},
										new Date()
									);
									if (uploadResult && result) {
										if (history) history.goBack("");
										if (hardReload) {
											window.location.reload();
										}
										notifications.show(config.notificationSettings.save("success", lang));
										if (setBusy) setBusy(true);
										return result;
									} else {
										notifications.show(config.notificationSettings.save("error", lang));
									}
								} catch (error) {
									console.error(error);
								}
							} else {
								if (result) {
									if (history) history.goBack("");
									if (hardReload) {
										window.location.reload();
									}
									notifications.show(config.notificationSettings.save("success", lang));
									if (setBusy) setBusy(true);
									return result;
								} else {
									notifications.show(config.notificationSettings.save("error", lang));
								}
							}
						});
					} else {
						try {
							if (result) {
								if (hardReload) {
									window.location.reload();
								}
								if (history) history.goBack("");
								notifications.show(config.notificationSettings.save("success", lang));
								if (setBusy) setBusy(true);
								return result;
							} else {
								notifications.show(config.notificationSettings.save("error", lang));
							}
						} catch (error) {
							console.error(error);
						}
					}
					return result;
				} catch (error) {
					console.error(error);
				}
			}
			return false;
		});
	} else {
		notifications.show(config.notificationSettings.save("error", lang));
		console.error("modelSendToServer is not defined");
		return false;
	}
};
export const DELETE = async ({ route, setBusy, id, history, lang, isBusy }) => {
	Swal.fire({
		title: T("areYouSure", lang),
		text: T("youCannotRevertThisAction", lang),
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		confirmButtonText: T("yes", lang)
	}).then(async (result) => {
		if (result.isConfirmed) {
			NProgress.start();
			let result;
			try {
				result = await API.remove(route, id);
				NProgress.done();
			} catch (error) {
				console.error(error);
				NProgress.done();
			}
			if (setBusy) setBusy(!isBusy);
			if (history) history.goBack();
			notifications.show(config.notificationSettings.delete("success", lang));
		}
	});
};
